// App.js
import React, { useState } from 'react';
import Nav from './components/Nav';
import Menu from './components/Menu';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';

const App = () => {
  const [showMenu, setShowMenu] = useState('');

  const toggleMenu = () => {
    setShowMenu(showMenu === '' ? 'active' : '');
  };

  return (
      <div id="root">
        {/* Навигационное меню */}
        <Nav showMenu={showMenu} toggleMenu={toggleMenu} />
        {/* Мобильное меню */}
        <Menu showMenu={showMenu} toggleMenu={toggleMenu} />
        {/* Шапка */}
        <Header />
        {/* О нас */}
        <About />
        {/* Проекты */}
        <Projects />
        {/* Контакты */}
        <Contact />
      </div>
  );
};

export default App;

import React from 'react';
import SocialLinks from "./SocialLinks";

const sendDataToServer = async (formData) => {
    try {
        const url = new URL('https://saintedlittle.nl/api.php');

        // Преобразование formData в query параметры URL
        Object.keys(formData).forEach(key => url.searchParams.append(key, formData[key]));

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded' // Изменяем Content-Type
            }
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log(responseData); // Выводим ответ сервера
            alert("Successful sent your message!")
        } else {
            console.error('Error:', response.statusText);
        }
    } catch (error) {
        console.error('Error:', error);
    }
};
const Contact = () => {
    const handleSubmit = (e) => {
        e.preventDefault();

        // Extract form data
        const formData = {
            name: e.target.name.value,
            email: e.target.email.value,
            message: e.target.message.value
        };

        sendDataToServer(formData);
    };

    return (
        <section id="contact">
            <div className="container">
                <div className="heading-wrapper">
                    <div className="heading">
                        <p className="title">Want to <br/>contact me?</p>
                        <p className="separator"></p>
                        <p className="subtitle">Please, use the form below or send an email to <span className="mail">efim<i className="fas fa-at at"></i>yataknemogy<i className="fas fa-circle dot"></i>com</span>:</p>
                    </div>
                    <SocialLinks />
                </div>
                {/* Form */}
                <form id="contact-form" onSubmit={handleSubmit}>
                    {/* Name input */}
                    <input placeholder="Name" name="name" type="text" required />
                    {/* Email input */}
                    <input placeholder="Email" name="email" type="email" required />
                    {/* Message input */}
                    <textarea placeholder="Message" name="message" />
                    {/* Submit button */}
                    <input className="button" id="submit" value="Submit" type="submit" />
                </form>
            </div>
        </section>
    );
};

export default Contact;

import React from 'react';

const projectsData = [{
    title: "Anonymous Message Board.",
    img: "https://i.imgur.com/7ve9rvg.jpeg",
    tech: "js css react node",
    link: "https://null.null",
    repo: "https://github.com/",
    description: "This is a full-stack website that I made which lets the users read and post anonymous messages and replies. Built using Node, Express, MongoDB, CSS + Bulma, React.js and React Router."
}, {
    title: "Stock Price Checker.",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Savannah_Cat_closeup.jpg/800px-Savannah_Cat_closeup.jpg",
    tech: "js node css",
    link: "https://null.null",
    repo: "https://github.com/",
    description: "Another full-stack website to check the current value of any requested stock in the market. Built using Node, Express, MongoDB, JS + jQuery + Axios, CSS + Bootstrap and Pug."
}];
const Project = (a) => {
    const b = {
        sass: "fab fa-sass",
        css: "fab fa-css3-alt",
        js: "fab fa-js-square",
        react: "fab fa-react",
        vue: "fab fa-vuejs",
        d3: "far fa-chart-bar",
        node: "fab fa-node"
    };
    const {title: title, img: img, tech: c, link: link, repo: repo, children: children} = a;
    return (
        <div className="project"><a className="project-link" href={link} target="_blank" rel="noopener noreferrer"><img
            className="project-image" src={img} alt={'Screenshot of ' + title}/></a>
            <div className="project-details">
                <div className="project-title"><p className="icons">{c.split(" ").map((a) => (
                    <i className={b[a]} key={a}></i>))}</p>{title}</div>
                {children}
                <div className="buttons"><a href={repo} target="_blank" rel="noopener noreferrer">View source <i
                    className="fas fa-external-link-alt"></i></a><a href={link} target="_blank"
                                                                    rel="noopener noreferrer">Try it Live <i
                    className="fas fa-external-link-alt"></i></a></div>
            </div>
        </div>)
};
const Projects = () => {
    return (<section id="projects">
        <div className="projects-container">
            <div className="heading"><h3 className="title">My Works</h3><p className="separator"></p><p
                className="subtitle">Here's a list of <u>most</u> of the projects I've been working on lately. All of
                these were built during my coding camp adventure on <a href="https://www.freecodecamp.org/"
                                                                       target="_blank"
                                                                       rel="noopener noreferrer">freeCodeCamp</a>, where
                I've been coding for almost 7 months non-stop until I completed all the projects required to get my
                Full-Stack Developer certification.</p></div>
            <div className="projects-wrapper">{projectsData.map((a, b) => (
                <Project key={b} title={a.title} img={a.img} tech={a.tech} link={a.link}
                         repo={a.repo}><small>{a.description}</small></Project>))}</div>
        </div>
    </section>)
};
export default Projects;

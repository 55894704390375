import React from 'react';

const Nav = ({showMenu, toggleMenu}) => {
    return (<nav id="navbar">
        <div className="nav-wrapper"><p className="brand">Yatak<strong>Nemogy</strong></p><a onClick={toggleMenu}
                                                                                             className={showMenu === 'active' ? 'menu-button active' : 'menu-button'}><span></span></a>
        </div>
    </nav>)
};
export default Nav;

import React from 'react';

const About = () => {
    return (<section id="about">
        <div className="wrapper">
            <article>
                <div className="title"><h3>Who's this guy?</h3><p className="separator"></p></div>
                <div className="desc full"><h4 className="subtitle">My name is yataknemogy.</h4><p>I am a web developer
                    and UX designer.</p><p>I am passionate about creating user-friendly interfaces and engaging web
                    experiences.</p><p>Outside of coding, I enjoy hiking, photography, and reading.</p></div>
                <div className="title"><h3>What does he do?</h3><p className="separator"></p></div>
                <div className="desc"><h4 className="subtitle">I'm a programmer.</h4><p>For the front-end, I specialize
                    in HTML, CSS, and JavaScript. I'm proficient in using modern frameworks like React and Vue.js.</p>
                    <p>On the back-end, I work with Node.js and Express.js, and I'm familiar with databases like MongoDB
                        and SQL.</p></div>
                <div className="desc"><h4 className="subtitle">Also a designer.</h4><p>I have experience with various
                    design tools such as Adobe Photoshop, Illustrator, and Figma. I enjoy creating visually appealing
                    designs that enhance the user experience.</p><p>Whether it's coding or designing, I strive to create
                    innovative solutions that make a positive impact.</p></div>
            </article>
        </div>
    </section>)
};
export default About;

import React from 'react';

const Header = () => {
    return (<header id="welcome-section">
        <div className="forest"></div>
        <div className="silhouette"></div>
        <div className="moon"></div>
        <div className="container"><h1><span className="line">I do</span><span
            className="line">graphics design</span><span className="line"><span className="color">&</span> code.</span>
        </h1>
            <div className="buttons"><a href="#projects">my portfolio</a><a href="#contact" className="cta">get in
                touch</a></div>
        </div>
    </header>)
};
export default Header;
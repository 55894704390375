import React from 'react';

const SocialLinks = () => {
    return (
        <div className="social">
            <a
                href="https://twitter.com/xxc1934881"
                target="_blank"
                rel="noopener noreferrer"
                title="Link to author's Twitter profile">
                <i className="fab fa-twitter"></i>
            </a>
            <a
                id="profile-link"
                href="https://github.com/yataknemogy"
                target="_blank"
                rel="noopener noreferrer"
                title="Link to author's GitHub Profile">
                 <i className="fab fa-github"></i>
            </a>
        </div>
    )
};
export default SocialLinks;
